var render = function () {
  var _vm$company, _vm$company2, _vm$company3, _vm$company4, _vm$company5, _vm$company6, _vm$grandTotalAmount, _vm$remarks, _vm$invoiceInfo, _vm$company7, _vm$company8, _vm$company9, _vm$company10, _vm$company11, _vm$company12, _vm$grandTotalAmount2, _vm$remarks2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "invoice-add-wrapper"
  }, [_c('b-row', {
    staticClass: "invoice-add"
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "xl": "9",
      "md": "8"
    }
  }, [_c('b-card', {
    staticClass: "invoice-preview-card",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', {
    staticClass: "invoice-padding pb-0"
  }, [_vm.showLogo ? _c('div', {
    staticClass: "d-flex align-items-center justify-content-center"
  }, [_c('div', {
    staticClass: "logo-wrapper"
  }, [(_vm$company = _vm.company) !== null && _vm$company !== void 0 && _vm$company.logo ? [_c('img', {
    staticClass: "mb-3",
    attrs: {
      "src": (_vm$company2 = _vm.company) === null || _vm$company2 === void 0 ? void 0 : _vm$company2.logo,
      "alt": "Company Logo",
      "height": "160"
    }
  })] : _vm._e()], 2)]) : _vm._e(), _c('b-row', {
    staticClass: "invoice-spacing"
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('h1', {
    staticClass: "mb-3"
  }, [_vm._v("INVOICE")]), _c('b', [_vm._v(_vm._s(_vm.sellerName))]), _vm.sellerEmail ? _c('div', [_vm._v(_vm._s(_vm.sellerEmail))]) : _vm._e(), _vm.sellerContact ? _c('div', [_vm._v(_vm._s(_vm.sellerContact))]) : _vm._e()]), _c('b-col', {
    staticClass: "text-md-left",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('div', {
    staticClass: "mb-3"
  }, [_c('h6', {
    staticClass: "invoice-title"
  }, [_vm._v("Invoice No.")]), _c('div', [_vm._v(_vm._s(_vm.invoiceId))])]), _c('div', {
    staticClass: "mb-3"
  }, [_c('h6', {
    staticClass: "invoice-title"
  }, [_vm._v("Invoice Date")]), _c('div', [_vm._v(_vm._s(_vm.invoiceDate))])])]), _c('b-col', {
    staticClass: "text-center text-md-left",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('h6', {
    staticClass: "mb-3"
  }, [_vm._v(_vm._s((_vm$company3 = _vm.company) === null || _vm$company3 === void 0 ? void 0 : _vm$company3.name))]), _c('div', {
    domProps: {
      "innerHTML": _vm._s((_vm$company4 = _vm.company) === null || _vm$company4 === void 0 ? void 0 : _vm$company4.address)
    }
  }), _c('p', {
    staticClass: "card-text mb-0"
  }, [_vm._v("Phone: " + _vm._s((_vm$company5 = _vm.company) === null || _vm$company5 === void 0 ? void 0 : _vm$company5.contact_no))]), _c('p', {
    staticClass: "card-text mb-0"
  }, [_vm._v("Email: " + _vm._s((_vm$company6 = _vm.company) === null || _vm$company6 === void 0 ? void 0 : _vm$company6.email))])])], 1)], 1), _c('b-card-body', {
    staticClass: "invoice-padding form-item-section"
  }, [_c('div', {
    ref: "form",
    staticClass: "repeater-form"
  }, [_c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table table-bordered"
  }, [_c('thead', {
    staticClass: "table-light"
  }, [_c('tr', [_c('th', [_vm._v("Item")]), _c('th', [_vm._v("Project")]), _c('th', [_vm._v("Type")]), _c('th', {
    staticClass: "text-end"
  }, [_vm._v("Amount (" + _vm._s(_vm.getCurrency()) + ")")])])]), _c('tbody', [_vm._l(_vm.invoiceItems, function (item, index) {
    var _item$project, _item$project$data;
    return _c('tr', {
      key: index
    }, [_c('td', [item.backlink_url ? _c('div', [_c('span', {
      staticClass: "font-weight-bold"
    }, [_vm._v("Backlink: ")]), _c('a', {
      attrs: {
        "href": _vm.getBacklinkUrl(item),
        "target": "_blank",
        "rel": "noopener noreferrer"
      }
    }, [_vm._v(" " + _vm._s(_vm.formatWebsite(_vm.getBacklinkUrl(item))) + " ")])]) : _vm._e(), item.targeted_url ? _c('div', [_c('span', {
      staticClass: "font-weight-bold"
    }, [_vm._v("URL: ")]), _c('a', {
      attrs: {
        "href": item.targeted_url,
        "target": "_blank",
        "rel": "noopener noreferrer"
      }
    }, [_vm._v(" " + _vm._s(item.targeted_url) + " ")])]) : _vm._e()]), _c('td', [_vm._v(_vm._s(((_item$project = item.project) === null || _item$project === void 0 ? void 0 : (_item$project$data = _item$project.data) === null || _item$project$data === void 0 ? void 0 : _item$project$data.title) || 'N/A'))]), _c('td', [_vm._v(_vm._s(item.backlinks_type_text || 'N/A'))]), _c('td', {
      staticClass: "text-end"
    }, [_vm._v(" " + _vm._s(item.cost_price || '0.00') + " ")])]);
  }), _c('tr', [_c('td', {
    staticClass: "text-right border-0",
    attrs: {
      "colspan": "3"
    }
  }, [_c('strong', [_vm._v("Subtotal")])]), _c('td', {
    staticClass: "text-end"
  }, [_c('strong', [_vm._v(_vm._s(_vm.subTotalAmount))])])]), _c('tr', [_c('td', {
    staticClass: "text-right border-0",
    attrs: {
      "colspan": "3"
    }
  }, [_c('strong', [_vm._v("Discount"), _vm.discountType === 'percentage' ? [_vm._v(" (" + _vm._s(_vm.discount_value) + "%)")] : _vm._e()], 2)]), _c('td', {
    staticClass: "text-end"
  }, [_c('strong', [_vm._v(_vm._s(_vm.discountAmount))])])]), _c('tr', [_c('td', {
    staticClass: "text-right border-0",
    attrs: {
      "colspan": "3"
    }
  }, [_c('strong', [_vm._v("Fee"), _vm.feeType === 'percentage' ? [_vm._v(" (" + _vm._s(_vm.fee_value) + "%)")] : _vm._e()], 2)]), _c('td', {
    staticClass: "text-end"
  }, [_c('strong', [_vm._v(_vm._s(_vm.feeAmount))])])]), _c('tr', [_c('td', {
    staticClass: "text-right border-0",
    attrs: {
      "colspan": "3"
    }
  }, [_c('strong', [_vm._v("Grand Total")])]), _c('td', {
    staticClass: "text-end text-danger"
  }, [_c('strong', [_vm._v(_vm._s(_vm.grandTotalAmount))])])])], 2)])]), _c('div', {
    staticClass: "mt-5"
  }, [_c('h4', {
    staticClass: "mb-1 ",
    staticStyle: {
      "text-transform": "capitalize"
    }
  }, [_vm._v("In Words: " + _vm._s(_vm.getCurrency()) + " " + _vm._s(_vm.numWords((_vm$grandTotalAmount = _vm.grandTotalAmount) !== null && _vm$grandTotalAmount !== void 0 ? _vm$grandTotalAmount : 0)) + " Only")]), _vm.remarks ? _c('h5', {
    staticClass: "mb-1"
  }, [_vm._v("Terms & Conditions:")]) : _vm._e(), _vm.remarks ? _c('b', [_vm._v(_vm._s((_vm$remarks = _vm.remarks) !== null && _vm$remarks !== void 0 ? _vm$remarks : "N/A"))]) : _vm._e()])])]), _c('h1', {
    staticClass: "text-center my-5"
  }, [_vm._v("Thank You For Your Business")]), _c('br')], 1)], 1), _c('b-col', {
    staticClass: "invoice-actions",
    attrs: {
      "cols": "12",
      "md": "4",
      "xl": "3"
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-toggle",
    attrs: {
      "id": "customSwitch1",
      "switch": ""
    },
    model: {
      value: _vm.showLogo,
      callback: function callback($$v) {
        _vm.showLogo = $$v;
      },
      expression: "showLogo"
    }
  }, [_vm._v(" Show Logo ")])], 1), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "6"
    }
  }, [((_vm$invoiceInfo = _vm.invoiceInfo) === null || _vm$invoiceInfo === void 0 ? void 0 : _vm$invoiceInfo.status) !== 'due' ? [_c('b-badge', {
    attrs: {
      "variant": "success"
    }
  }, [_vm._v("Paid")])] : [_c('b-badge', {
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v("Due")])]], 2)], 1), _vm.$permissionAbility(_vm.OUTREACH_INVOICE_PAYMENT, _vm.permissions) ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mb-75",
    attrs: {
      "disabled": _vm.status === _vm.paymentPaidConstants,
      "variant": "success",
      "block": ""
    },
    on: {
      "click": function click($event) {
        return _vm.payInvoice();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.status === _vm.paymentPaidConstants ? "Paid" : "Pay") + " ")]) : _vm._e(), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mb-75",
    attrs: {
      "variant": "outline-secondary",
      "block": ""
    },
    on: {
      "click": _vm.printInvoice
    }
  }, [_vm._v(" Print ")])], 1), _c('b-card', [_c('b-col', {
    staticClass: "mt-xl-0 mt-2",
    attrs: {
      "cols": "12"
    }
  }, [_vm.paymentMethod ? _c('div', [_c('table', [_c('tbody', [_c('tr', [_c('td', [_c('b', [_vm._v("Payment Method:")])]), _c('td', [_vm._v(_vm._s(_vm.paymentMethod))])])])]), _vm.attachments.length > 0 ? _c('div', [_c('div', [_c('b', [_vm._v("Attachments:")])]), _vm._l(_vm.attachments, function (attachment) {
    return _c('div', {
      key: attachment.id
    }, [_c('b-link', {
      staticClass: "d-flex align-items-center",
      attrs: {
        "href": attachment.url,
        "target": "_blank"
      }
    }, [_c('feather-icon', {
      staticClass: "mr-2",
      attrs: {
        "icon": "EyeIcon"
      }
    }), _vm._v(" Attachment ")], 1)], 1);
  })], 2) : _vm._e(), _vm.paymentDetails ? _c('div', [_c('div', [_c('b', [_vm._v("Payment Details:")])]), _c('br'), _c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.paymentDetails)
    }
  })]) : _vm._e()]) : _vm._e()])], 1)], 1)], 1), _c('div', {
    staticStyle: {
      "width": "90%",
      "margin": "0",
      "padding": "0",
      "display": "none"
    },
    attrs: {
      "id": "content"
    }
  }, [_c('div', {
    staticStyle: {
      "width": "90%",
      "max-width": "800px",
      "margin": "auto"
    }
  }, [_vm.showLogo ? _c('div', {
    staticStyle: {
      "text-align": "center",
      "margin-bottom": "20px"
    }
  }, [_c('div', {
    staticStyle: {
      "margin-bottom": "20px"
    }
  }, [(_vm$company7 = _vm.company) !== null && _vm$company7 !== void 0 && _vm$company7.logo ? _c('img', {
    attrs: {
      "src": (_vm$company8 = _vm.company) === null || _vm$company8 === void 0 ? void 0 : _vm$company8.logo,
      "alt": "Company Logo",
      "height": "160"
    }
  }) : _vm._e()])]) : _c('div', [_c('div', {
    staticStyle: {
      "margin-top": "160px"
    }
  })]), _c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "space-between"
    }
  }, [_c('div', {
    staticStyle: {
      "width": "37%",
      "overflow-wrap": "break-word"
    }
  }, [_c('h1', {
    staticStyle: {
      "margin-top": "0px",
      "font-size": "28px"
    }
  }, [_vm._v("INVOICE")]), _c('b', [_vm._v(_vm._s(_vm.sellerName))]), _vm.sellerEmail ? _c('div', [_vm._v(_vm._s(_vm.sellerEmail))]) : _vm._e(), _vm.sellerContact ? _c('div', [_vm._v(_vm._s(_vm.sellerContact))]) : _vm._e()]), _c('div', {
    staticStyle: {
      "text-align": "center",
      "width": "26%",
      "overflow-wrap": "break-word"
    }
  }, [_c('div', {
    staticClass: "margin-bottom:5px"
  }, [_c('strong', [_vm._v("Invoice No.")]), _c('div', {
    staticStyle: {
      "padding": "5px"
    }
  }, [_vm._v("#" + _vm._s(_vm.invoiceId))])]), _c('br'), _c('div', {
    staticClass: "margin-bottom:5px"
  }, [_c('strong', [_vm._v("Invoice Date")]), _c('div', {
    staticStyle: {
      "padding": "5px"
    }
  }, [_vm._v(_vm._s(_vm.invoiceDate))])]), _c('br')]), _c('div', {
    staticStyle: {
      "width": "37%",
      "overflow-wrap": "break-word"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$company9 = _vm.company) === null || _vm$company9 === void 0 ? void 0 : _vm$company9.name))]), _c('br'), _c('br'), _c('div', [_vm._v(_vm._s((_vm$company10 = _vm.company) === null || _vm$company10 === void 0 ? void 0 : _vm$company10.address))]), _c('div', [_vm._v("Phone: " + _vm._s((_vm$company11 = _vm.company) === null || _vm$company11 === void 0 ? void 0 : _vm$company11.contact_no))]), _c('div', [_vm._v("Email: " + _vm._s((_vm$company12 = _vm.company) === null || _vm$company12 === void 0 ? void 0 : _vm$company12.email))])])]), _c('table', {
    staticStyle: {
      "width": "100%",
      "border-collapse": "collapse",
      "margin-top": "20px"
    }
  }, [_c('thead', [_c('tr', [_c('th', {
    staticStyle: {
      "border": "1px solid black",
      "padding": "8px"
    }
  }, [_vm._v("Item")]), _c('th', {
    staticStyle: {
      "border": "1px solid black",
      "padding": "8px"
    }
  }, [_vm._v("Project")]), _c('th', {
    staticStyle: {
      "border": "1px solid black",
      "padding": "8px"
    }
  }, [_vm._v("Type")]), _c('th', {
    staticStyle: {
      "border": "1px solid black",
      "padding": "8px",
      "text-align": "right",
      "width": "10%"
    }
  }, [_vm._v("Amount (" + _vm._s(_vm.getCurrency()) + ")")])])]), _c('tbody', [_vm._l(_vm.invoiceItems, function (item, index) {
    var _item$project2, _item$project2$data;
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticStyle: {
        "border": "1px solid black",
        "padding": "8px"
      }
    }, [item.backlink_url ? _c('div', [_c('span', {
      staticStyle: {
        "font-weight": "bold"
      }
    }, [_vm._v("Backlink: ")]), _c('a', {
      attrs: {
        "href": _vm.getBacklinkUrl(item),
        "target": "_blank",
        "rel": "noopener noreferrer"
      }
    }, [_vm._v(_vm._s(_vm.formatWebsite(_vm.getBacklinkUrl(item))))])]) : _vm._e(), item.targeted_url ? _c('div', [_c('span', {
      staticStyle: {
        "font-weight": "bold"
      }
    }, [_vm._v("URL: ")]), _c('a', {
      attrs: {
        "href": item.targeted_url,
        "target": "_blank",
        "rel": "noopener noreferrer"
      }
    }, [_vm._v(_vm._s(item.targeted_url))])]) : _vm._e()]), _c('td', {
      staticStyle: {
        "border": "1px solid black",
        "padding": "8px"
      }
    }, [_vm._v(_vm._s(((_item$project2 = item.project) === null || _item$project2 === void 0 ? void 0 : (_item$project2$data = _item$project2.data) === null || _item$project2$data === void 0 ? void 0 : _item$project2$data.title) || 'N/A'))]), _c('td', {
      staticStyle: {
        "border": "1px solid black",
        "padding": "8px"
      }
    }, [_vm._v(_vm._s(item.backlinks_type_text || 'N/A'))]), _c('td', {
      staticStyle: {
        "border": "1px solid black",
        "padding": "8px",
        "text-align": "right",
        "width": "10%"
      }
    }, [_vm._v(_vm._s(item.cost_price || '0.00'))])]);
  }), _c('tr', [_c('td', {
    staticStyle: {
      "padding": "10px",
      "text-align": "right",
      "border": "none"
    },
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v("Subtotal")]), _c('td', {
    staticStyle: {
      "padding": "10px",
      "text-align": "right",
      "border": "1px solid black"
    }
  }, [_vm._v(_vm._s(_vm.subTotalAmount))])]), _c('tr', [_c('td', {
    staticStyle: {
      "padding": "10px",
      "text-align": "right",
      "border": "none"
    },
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v("Discount"), _vm.discountType === 'percentage' ? [_vm._v(" (" + _vm._s(_vm.discount_value) + "%)")] : _vm._e()], 2), _c('td', {
    staticStyle: {
      "padding": "10px",
      "text-align": "right",
      "border": "1px solid black"
    }
  }, [_vm._v(_vm._s(_vm.discountAmount))])]), _c('tr', [_c('td', {
    staticStyle: {
      "padding": "10px",
      "text-align": "right",
      "border": "none"
    },
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v("Fee"), _vm.feeType === 'percentage' ? [_vm._v(" (" + _vm._s(_vm.fee_value) + "%)")] : _vm._e()], 2), _c('td', {
    staticStyle: {
      "padding": "10px",
      "text-align": "right",
      "border": "1px solid black"
    }
  }, [_vm._v(_vm._s(_vm.feeAmount))])]), _c('tr', [_c('td', {
    staticClass: "text-danger",
    staticStyle: {
      "padding": "10px",
      "text-align": "right",
      "border": "none"
    },
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v("Grand Total")]), _c('td', {
    staticStyle: {
      "padding": "10px",
      "text-align": "right",
      "border": "1px solid black"
    }
  }, [_vm._v(_vm._s(_vm.grandTotalAmount))])])], 2)]), _c('div', {
    staticStyle: {
      "margin-top": "30px"
    }
  }, [_c('h4', {
    staticStyle: {
      "font-size": "16px",
      "text-transform": "capitalize"
    }
  }, [_vm._v("In Words: " + _vm._s(_vm.getCurrency()) + " " + _vm._s(_vm.numWords((_vm$grandTotalAmount2 = _vm.grandTotalAmount) !== null && _vm$grandTotalAmount2 !== void 0 ? _vm$grandTotalAmount2 : 0)) + " Only")]), _vm.remarks ? _c('h4', [_c('strong', [_vm._v("Note/Terms")])]) : _vm._e(), _vm.remarks ? _c('p', [_vm._v(_vm._s((_vm$remarks2 = _vm.remarks) !== null && _vm$remarks2 !== void 0 ? _vm$remarks2 : "N/A"))]) : _vm._e()]), _c('h2', {
    staticStyle: {
      "text-align": "center",
      "margin-top": "50px",
      "margin-bottom": "50px"
    }
  }, [_vm._v("Thank You For Your Business")]), _c('br')])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }