<template>
  <section class="invoice-add-wrapper">

    <b-row class="invoice-add">
      <b-col cols="12" xl="9" md="8">
        <b-card no-body class="invoice-preview-card">

          <b-card-body class="invoice-padding pb-0">
            <div v-if="showLogo" class="d-flex align-items-center justify-content-center">
              <div class="logo-wrapper">
                <template v-if="company?.logo">
                  <img :src="company?.logo" alt="Company Logo" height="160" class="mb-3" />
                </template>
              </div>
            </div>

            <b-row class="invoice-spacing">
              <!-- To Section -->
              <b-col cols="12" md="4">
                <h1 class="mb-3">INVOICE</h1>
                <b>{{ sellerName }}</b>
                <div v-if="sellerEmail">{{ sellerEmail }}</div>
                <div v-if="sellerContact">{{ sellerContact }}</div>
              </b-col>

              <!-- Date Section -->
              <b-col cols="12" md="4" class="text-md-left">
                <div class="mb-3">
                  <h6 class="invoice-title">Invoice No.</h6>
                  <div>{{ invoiceId }}</div>
                </div>

                <div class="mb-3">
                  <h6 class="invoice-title">Invoice Date</h6>
                  <div>{{ invoiceDate }}</div>
                </div>

                <!--                <div class="mb-3">-->
                <!--                  <h6 class="invoice-title">Due Date</h6>-->
                <!--                  <div>{{ paymentDate }}</div>-->
                <!--                </div>-->
              </b-col>

              <!-- From Section -->
              <b-col cols="12" md="4" class="text-center text-md-left">
                <h6 class="mb-3">{{ company?.name }}</h6>
                <div v-html="company?.address"></div>
                <p class="card-text mb-0">Phone: {{ company?.contact_no }}</p>
                <p class="card-text mb-0">Email: {{ company?.email }}</p>
              </b-col>
            </b-row>
          </b-card-body>

              <b-card-body class="invoice-padding form-item-section">
                <div ref="form" class="repeater-form">
                  <div class="table-responsive">
                    <table class="table table-bordered" >
                      <thead class="table-light">
                      <tr>
                        <th >Item</th>
                        <th >Project</th>
                        <th >Type</th>
                        <th  class="text-end">Amount ({{ getCurrency() }})</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(item, index) in invoiceItems" :key="index">
                        <td>
                          <div v-if="item.backlink_url">
                            <span class="font-weight-bold">Backlink: </span>
                            <a :href="getBacklinkUrl(item)"
                               target="_blank"
                               rel="noopener noreferrer">
                              {{ formatWebsite(getBacklinkUrl(item)) }}
                            </a>
                          </div>
                          <div v-if="item.targeted_url">
                            <span class="font-weight-bold">URL: </span>
                            <a :href="item.targeted_url"
                               target="_blank"
                               rel="noopener noreferrer">
                              {{ item.targeted_url }}
                            </a>
                          </div>
                        </td>
                        <td>{{ item.project?.data?.title || 'N/A' }}</td>
                        <td>{{ item.backlinks_type_text || 'N/A' }}</td>
                        <td class="text-end">
                          {{ item.cost_price || '0.00' }}
                        </td>
                      </tr>

                  <tr>
                    <td colspan="3" class="text-right border-0"><strong>Subtotal</strong></td>
                    <td class="text-end"><strong>{{ subTotalAmount }}</strong></td>
                  </tr>
                  <tr>
                    <td colspan="3" class="text-right border-0"><strong>Discount<template v-if="discountType === 'percentage'"> ({{ discount_value }}%)</template></strong></td>
                    <td class="text-end"><strong>{{ discountAmount }}</strong></td>
                  </tr>
                  <tr>
                    <td colspan="3" class="text-right border-0"><strong>Fee<template v-if="feeType === 'percentage'"> ({{ fee_value }}%)</template></strong></td>
                    <td class="text-end"><strong>{{ feeAmount }}</strong></td>
                  </tr>
                  <tr>
                    <td colspan="3" class="text-right border-0"><strong>Grand Total</strong></td>
                    <td class="text-end text-danger"><strong>{{ grandTotalAmount }}</strong></td>
                  </tr>

                  </tbody>
                </table>
              </div>

                  <div class="mt-5">
                    <h4 class="mb-1 " style="text-transform: capitalize;">In Words: {{ getCurrency() }} {{ numWords(grandTotalAmount ?? 0) }} Only</h4>
                    <h5 v-if="remarks" class="mb-1">Terms & Conditions:</h5>
                     <b v-if="remarks">{{ remarks ?? "N/A" }}</b>
                  </div>
                </div>

              </b-card-body>

          <h1 class="text-center my-5">Thank You For Your Business</h1>

          <br>

        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col cols="12" md="4" xl="3" class="invoice-actions">
        <!-- Action Buttons -->
        <b-card>

          <b-row>
            <b-col cols="6">
              <b-form-checkbox
                  id="customSwitch1"
                  v-model="showLogo"
                  switch
                  class="custom-toggle"
              >
                Show Logo
              </b-form-checkbox>
            </b-col>
            <b-col cols="6" class="text-right">
              <template v-if="invoiceInfo?.status !== 'due'">
                <b-badge variant="success">Paid</b-badge>
              </template>
              <template v-else>
                <b-badge variant="danger">Due</b-badge>
              </template>
            </b-col>
          </b-row>

          <b-button
              v-if="$permissionAbility(OUTREACH_INVOICE_PAYMENT, permissions)"
              :disabled="status === paymentPaidConstants"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="success"
              class="mb-75"
              block
              @click="payInvoice()"
          >
            {{ status === paymentPaidConstants ? "Paid" : "Pay" }}
          </b-button>

          <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              class="mb-75"
              block
              v-on:click="printInvoice"
          >
            Print
          </b-button>


          <!--          <div class="my-3">-->
          <!--            <h6>Reviewer:</h6>-->
          <!--            <UserCard :user="approver" />-->
          <!--          </div>-->

        </b-card>

        <!--        Payment card-->
        <b-card>
          <b-col
              cols="12"
              class="mt-xl-0 mt-2"
          >
            <div v-if="paymentMethod">
              <table>
                <tbody>
                <tr>
                  <td><b>Payment Method:</b></td>
                  <td>{{ paymentMethod }}</td>
                </tr>
                </tbody>
              </table>

              <div v-if="attachments.length > 0">
                <div><b>Attachments:</b></div>
                <div v-for="attachment in attachments" :key="attachment.id">

                  <b-link :href="attachment.url" target="_blank" class="d-flex align-items-center">
                    <feather-icon icon="EyeIcon" class="mr-2" />
                    Attachment
                  </b-link>

                </div>
              </div>

              <div v-if="paymentDetails">
                <div>
                  <b>Payment Details:</b>
                </div>
                <br>
                <div v-html="paymentDetails"></div>
              </div>
            </div>
          </b-col>
        </b-card>
      </b-col>
    </b-row>

    <!-- Printed Invoice  -->
    <div id="content" style="width: 90%;  margin: 0; padding: 0; display: none">
      <div style="width: 90%; max-width: 800px; margin: auto; ">
        <div v-if="showLogo" style="text-align: center; margin-bottom: 20px;">
          <div style="margin-bottom: 20px;">
            <img v-if="company?.logo" :src="company?.logo" alt="Company Logo" height="160" />
          </div>
        </div>
        <div v-else>
          <div style="margin-top: 160px"></div>
        </div>

        <div style="display: flex; justify-content: space-between;">

          <div style="width: 37%; overflow-wrap: break-word;" >
            <h1 style="margin-top: 0px; font-size: 28px">INVOICE</h1>
            <b>{{ sellerName }}</b>
            <div v-if="sellerEmail">{{ sellerEmail }}</div>
            <div v-if="sellerContact">{{ sellerContact }}</div>
          </div>

          <div style="text-align: center; width: 26%; overflow-wrap: break-word;">
            <div class="margin-bottom:5px">
              <strong>Invoice No.</strong>
              <div style="padding: 5px;">#{{ invoiceId }}</div>
            </div>
            <br>
            <div class="margin-bottom:5px">
              <strong>Invoice Date</strong>
              <div style="padding: 5px;">{{ invoiceDate }}</div>
            </div>
            <br>
            <!--            <div class="margin-bottom:5px">-->
            <!--              <strong>Due Date</strong>-->
            <!--              <div style="padding: 5px;">{{ paymentDate }}</div>-->
            <!--            </div>-->
          </div>

          <div style="width: 37%; overflow-wrap: break-word;" >
            <strong>{{ company?.name }}</strong>
            <br>
            <br>
            <div>{{ company?.address }}</div>
            <div>Phone: {{ company?.contact_no }}</div>
            <div>Email: {{ company?.email }}</div>
          </div>
        </div>

        <table style="width: 100%; border-collapse: collapse; margin-top: 20px;">
          <thead>
          <tr>
            <th style="border: 1px solid black; padding: 8px;">Item</th>
            <th style="border: 1px solid black; padding: 8px;">Project</th>
            <th style="border: 1px solid black; padding: 8px;">Type</th>
            <th style="border: 1px solid black; padding: 8px; text-align: right; width: 10%">Amount ({{ getCurrency() }})</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in invoiceItems" :key="index">
            <td style="border: 1px solid black; padding: 8px;">
              <div v-if="item.backlink_url">
                <span style="font-weight: bold;">Backlink: </span>
                <a :href="getBacklinkUrl(item)" target="_blank" rel="noopener noreferrer">{{ formatWebsite(getBacklinkUrl(item)) }}</a>
              </div>
              <div v-if="item.targeted_url">
                <span style="font-weight: bold;">URL: </span>
                <a :href="item.targeted_url" target="_blank" rel="noopener noreferrer">{{ item.targeted_url }}</a>
              </div>
            </td>
            <td style="border: 1px solid black; padding: 8px;">{{ item.project?.data?.title || 'N/A' }}</td>
            <td style="border: 1px solid black; padding: 8px;">{{ item.backlinks_type_text || 'N/A' }}</td>
            <td style="border: 1px solid black; padding: 8px; text-align: right;width: 10%">{{ item.cost_price || '0.00' }}</td>
          </tr>

          <tr>
            <td colspan="3" style="padding: 10px; text-align: right; border: none">Subtotal</td>
            <td style="padding: 10px; text-align: right; border: 1px solid black;">{{ subTotalAmount }}</td>
          </tr>
          <tr>
            <td colspan="3" style="padding: 10px; text-align: right; border: none">Discount<template v-if="discountType === 'percentage'"> ({{ discount_value }}%)</template></td>
            <td style="padding: 10px; text-align: right; border: 1px solid black;">{{ discountAmount }}</td>
          </tr>
          <tr>
            <td colspan="3" style="padding: 10px; text-align: right; border: none">Fee<template v-if="feeType === 'percentage'"> ({{ fee_value }}%)</template></td>
            <td style="padding: 10px; text-align: right; border: 1px solid black;">{{ feeAmount }}</td>
          </tr>

          <tr>
            <td colspan="3" class="text-danger" style="padding: 10px; text-align: right; border: none">Grand Total</td>
            <td style="padding: 10px; text-align: right; border: 1px solid black;">{{ grandTotalAmount }}</td>
          </tr>
          </tbody>
        </table>

        <div style="margin-top: 30px;">
          <h4 style="font-size: 16px;text-transform: capitalize;">In Words: {{ getCurrency() }} {{ numWords(grandTotalAmount ?? 0) }} Only</h4>
          <h4 v-if="remarks"><strong>Note/Terms</strong></h4>
          <p v-if="remarks">{{ remarks ?? "N/A" }}</p>
        </div>

        <h2 style="text-align: center; margin-top: 50px; margin-bottom: 50px;">Thank You For Your Business</h2>
        <br>

      </div>
    </div>

  </section>
</template>

  <script>
  import {
    BCard,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BForm,
    BModal,
    BSpinner,
    BRow,
    BCol,
    VBTooltip,
    BCardBody,
    BCardText,
    VBToggle,
    BLink,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BTableLite, BFormCheckbox,
  } from "bootstrap-vue";
  import { VueGoodTable } from "vue-good-table";
  import Ripple from "vue-ripple-directive";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { required, max } from "@validations";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import { mapGetters } from "vuex";
  import UserAssignPolicyForm from "@/views/admin/payroll-management/UserAssignPolicyForm.vue";
  import flatPickr from "vue-flatpickr-component";
  import Logo from "@core/layouts/components/Logo.vue";
  import {
    OUTREACH_INVOICE_PAYMENT
  } from "@/helpers/permissionsConstant";
  import UserCard from "@/layouts/components/UserCard.vue";
  import { paymentPaidConstants, paymentUnpaidConstants, paymentDueConstants, outreachInvoiceStatusConstants } from "@/helpers/constant/outreachPaymentStatusConstant";
  import numWords from "num-words";

  export default {
    name: "OutreachInvoiceDetailsView",
    components: {
      BFormCheckbox,
      UserCard,
      UserAssignPolicyForm,
      BForm,
      BButton,
      BCard,
      VueGoodTable,
      BAvatar,
      BBadge,
      BPagination,
      BFormGroup,
      BFormInput,
      BFormSelect,
      BDropdown,
      BDropdownItem,
      ValidationProvider,
      ValidationObserver,
      BModal,
      BSpinner,
      BRow,
      BCol,
      VBTooltip,
      BCardBody,
      BCardText,
      VBToggle,
      BLink,
      Logo,
      BInputGroup,
      BInputGroupPrepend,
      flatPickr,
      BFormTextarea,
      BTableLite,


    },
    directives: {
      "b-tooltip": VBTooltip,
    'b-toggle': VBToggle,

      Ripple,
    },
    data() {
      return {

        OUTREACH_INVOICE_PAYMENT,

        paymentPaidConstants,
        paymentUnpaidConstants,
        paymentDueConstants,
        outreachInvoiceStatusConstants,

        showLogo: true,
        attachments: null,
        approver: null,
        clientAddress: "",

        invoiceItems: [],

        // data
        companyName: "",
        officeAddress: "",
        officeEmail: "",
        officeMobile: "",

        sellerName: "",
        sellerEmail: "",
        sellerContact: "",

        invoiceId: "",
        invoiceDate: "",
        status: "",
        remarks: "",
        paymentDate: "",

        targetedLink: "",
        costPrice: "",
        currency: "",

        subTotalAmount: 0,
        feeAmount: 0,
        discountType: '',
        discount_value: 0,

        feeType: '',
        fee_value: 0,
        discountAmount: 0,
        grandTotalAmount: 0,

        paymentMethod: "",
        paymentDetails: "",
      };
    },

    computed: {
      ...mapGetters({
        permissions: "userModule/getPermissions",
      }),
      statusVariant() {
        const statusColor = {
          true: "light-success",
          false: "light-danger",
        };

        return (status) => statusColor[status];
      },
      company() {
        return this.$store.getters["companyModule/data"];
      },
    },

    async created() {
      await this.$store.dispatch("companyModule/fetchCompanyData");
      this.loadItems();
    },
    methods: {
      numWords,

      getCurrency()
      {
        return this.invoiceItems[0]?.currency?.data?.currency
      },

      payInvoice() {
        this.$swal({
          title: "Warning!",
          text: "Are You Sure You Want To Pay This?",
          icon: "warning",
          html: `
            <div class="swal-content">
              <p>Please enter your password to confirm the payment.</p>
              <input type="password" id="swal-password" class="swal2-input form-control" placeholder="Enter your password">
              <p class="mt-2">If you have any file please attach below.</p>
              <input type="file" id="swal-files" class="swal2-file form-control my-1" multiple>
            </div>
    `,
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: "Ok",
          showLoaderOnConfirm: true,
          preConfirm: () => {
            const password = document.getElementById("swal-password").value;
            const fileInput = document.getElementById("swal-files");
            const files = fileInput.files;

            if (!password) {
              this.$swal.showValidationMessage("Password is required.");
              return false;
            }

            return { password, files };
          },
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              const id = this.$route.params.id;
              const formData = new FormData();
              formData.append("password", result.value.password);

              // Append multiple files
              for (let i = 0; i < result.value.files.length; i++) {
                formData.append("attachments[]", result.value.files[i]);
              }

              formData.append("_method", "PUT");

              // Send the form data with files and password
              await this.$api.post(`/api/outreach-invoices/${id}/pay`, formData, {
                headers: { "Content-Type": "multipart/form-data" },
              });

              this.loadItems();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Invoice Successfully Paid",
                },
              });
            } catch (error) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message || "An error occurred.",
                },
              });
            }
          }
        });
      },


      getBacklinkUrl(backlink) {
        return `${backlink?.site_list?.website}/${backlink.backlink_url}`;
      },


      formatWebsite(website) {
        const maxLength = 20;
        if (website.length > maxLength) {
          return website.slice(0, maxLength) + "...";
        }
        return website;
      },

      formatDateTime(value) {
        if (value) {
          return this.$moment(value).format("MMM Do YYYY");
        }
      },

      formatDate(value) {
        if (value) {
          return this.$moment(value).format("MMM Do YYYY");
        }
        return "Not Set";
      },

    async printInvoice() {
      const printSection = document.getElementById('content');
      // const originalContent = document.body.innerHTML;

      // Create a new window for printing
      const newWindow = window.open('', '_blank', 'width=600,height=400');
      newWindow.document.write(`
        <html>
          <head>
            <title>Print</title>
            <style>
              @page {
                  size: auto;
                  margin: 20px;
                }

              /* Add any styles you want for the printed content here */
              body {
                font-family: Arial, sans-serif;
                margin: 20px;
              }
              /* Example: If you want page breaks to happen automatically */
              .page-break { page-break-before: always; }

              /* Ensure the print layout is clean and well-organized */
              @media print {
                body {
                  margin: 0;
                  padding: 0;
                  font-size: 14px;
                }
                .no-print {
                  display: none;
                }
                /* Custom styling for your content */
              }
            </style>
          </head>
          <body>
            ${printSection.innerHTML}
          </body>
        </html>
      `);
      newWindow.document.close(); // Close the document
      newWindow.print(); // Trigger the print dialog
      newWindow.addEventListener('afterprint', function() {
          newWindow.close();
      });
    },
      async getOutreachInvoiceItems() {
        const invoiceId = this.$route.params.id;
        return await this.$api.get(`api/outreach-invoices/${invoiceId}?include=site_list,site_list.seller,backlinks,backlinks.project, backlinks.currency ,approver`);
      },
      async getSetting(params) {
        return await this.$api.get("/api/settings", {
          params: {
            select: params.select,
          },
        });
      },

      async loadItems() {
        try {

          const [getSetting, outreachInvoiceDetails ] = await Promise.all([
          this.getSetting({
            select: "account_id",
          }),
          this.getOutreachInvoiceItems(),
        ]);

        const data = outreachInvoiceDetails?.data?.data;

          this.attachments = data?.attachments

          this.sellerName = data?.seller?.name
          this.sellerEmail = data?.seller?.email
          this.sellerContact = data?.seller?.contact_no

          this.status = data?.status ;
          this.remarks = data?.remarks ;
          this.invoiceId = data?.invoice_number ;
          this.invoiceDate = this.formatDateTime(data?.created_at) ;
          this.paymentDate = this.formatDateTime(data?.payment_date) ;
          this.approver = data?.approver?.data ;

        this.paymentMethod = data?.payment_method_text ;
        this.paymentDetails = data?.payment_details ;

        this.invoiceItems = data?.backlinks?.data

          this.subTotalAmount = data?.total_amount;
          this.feeAmount = data?.fee_amount;
          this.discountType = data?.discount_type;
          this.discount_value = data?.discount_value;

          this.feeType = data?.fee_type;
          this.fee_value = data?.fee_value;

          this.discountAmount = data?.discount_amount;
          this.grandTotalAmount = data?.grand_total;

          this.feeType = data?.fee_type;
          this.fee_value = data?.fee_value;

        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Warning",
              icon: "BellIcon",
              variant: "warning",
              text: error?.response?.data?.message,
            },
          });
        }
      },


    },
  };
  </script>

  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
 @import "~@core/scss/base/pages/app-invoice.scss";

 .form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}

.custom-width {
  max-width: 25rem !important;
}
  </style>

